window.cpmt = {
    noop: function () {
        return true;
    },
    gaEvent: function (cat, act, lbl, val, fld) {
        const t = window.ga && window.ga.getByName && window.ga.getByName('cpmt');
        t && t.send('event', cat, act, lbl, val, fld);
    },
    gaId: function (id) {
        this.gaEvent('Signup', 'Identified', id);
    },
    mpEvent: function (event, obj) {
        if (window.mixpanel && window.mixpanel.track) {
            window.mixpanel.track(event, obj);
            window.mixpanel.acc.track(event, obj);
        }
        if (window.amplitude && window.amplitude.getInstance) {
            window.amplitude.getInstance().logEvent(event, obj);
            window.amplitude.getInstance("acc").logEvent(event, obj);
        }
    },
    mpId: function (id, accId) {
        if (window.mixpanel && window.mixpanel.alias) {
            if (id)
                window.mixpanel.alias(id);
            if (accId)
                window.mixpanel.acc.alias(accId);
        }
        if (window.amplitude && window.amplitude.getInstance) {
            if (id)
                window.amplitude.getInstance().identify(id);
            if (accId)
                window.amplitude.getInstance("acc").identify(accId);
        }
    },
    mpClick: function (id, event, obj) {
        document.getElementById(id).addEventListener("click", function () {
            window.cpmt.mpEvent(event, obj);
            return true;
        });
    }
}