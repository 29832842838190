import 'whatwg-fetch';
import Cookies from 'js-cookie';
import './casualpm_cpmt.js'

const localhost = document.location.host === "localhost:5000";
const path = localhost ? "http://localhost:8081/signup/config/record" : "/signup/config/record";

const VISITOR = "casual_visitor";
const SESSION = "casual_session";
const AGE = 60;

async function onLoad() {
    const visitorId = Cookies.get(VISITOR);
    const session = Cookies.get(SESSION);

    const response = await window.fetch(path, {
        method: 'POST',
        body: JSON.stringify({
            "l": document.location.href,
            "r": document.referrer,
            "v": visitorId,
            "s": session
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();

    if (json["v"]) {
        Cookies.set(VISITOR, json["v"], {expires: AGE, path: "/"});
    }
    if (json["s"]) {
        Cookies.set(SESSION, json["s"], {path: "/"});
    }
}

document.addEventListener("DOMContentLoaded", onLoad);
